<template>
  <div class="footer">
      <div class="footer_container">
        <div class="bottom_tab">
              <router-link class="tab_link" to="/trems">User Agreement </router-link>
        
              <router-link class="tab_link" to='/aboutus'>About Us </router-link>
              <!-- <router-link class="tab_link" to='/fair'>Probability Fairness</router-link> -->
          </div>
          <div class="copyright">©2024 playsavefirst all rights reserved</div>
          <div class="copyright">Company Address: LAT 1512，15/F，LUCKY CENTRE,NO.165-171 WAN CHAI ROAD, WAN CHAI HK</div>
          <div class="copyright">E-mail:  Customer-service@playsavefirst.com</div>
          <div class="copyright" style="text-align: center;margin-top:30px;">HONG KONG SHENLIAN TRADING CO., LIMITED</div>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang='scss' scoped>
.footer{
   padding:32px 16px;
    background:#f0f0f7;
    .footer_container{
        width:100%;
        text-align: left;
        .copyright{
            margin:5px auto;
            font-size:10px;
            color:#777584;
            margin-bottom:10px;
        }
        .bottom_tab{
            font-size:10px;
            color:#000;
            margin:20px auto;
           
            .tab_link{
                padding:0 5px;
                // border-left:1px solid #ccc;
                cursor: pointer;
                color:#000;
                // text-decoration-line: none;
                text-decoration-line: underline;
              
            }
        }
    }
}

</style>